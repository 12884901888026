
import React,{useState, useRef, useEffect} from 'react';
import './App.css';
// import {onAuthStateChanged } from 'firebase/auth'
// import {auth} from './firebase/firebase'
import LoginPage from './components/Login/LoginPage';
import Support from './pages/Support';
// import PreSalePage from './components/PreSaleButtonPage/PreSalePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import necessary components from React Router
import Home from './components/Home/Home';
import Dashboard from './pages/Dashboard';
import Affiliates from './pages/Affiliates';
import FundedAccounts from './pages/FundedAccounts';
import Platforms from './pages/Platforms';
import TopWorldwide from './pages/TopWorldwide';
import EmailRegistration from './components/EmailRegistration/EmailRegistration';
import PrivacyPolicy from './components/Footer/PrivacyPolicy';
import RefundAndCancellation from './components/Footer/RefundAndCancellation';
import GeneralDisclaimer from './components/Footer/GeneralDisclaimer';
import TermsAndCondition from './components/Footer/TermsAndCondition';
import SignUp from './components/SignUp/SignUp';
import SkrillSuccess from './components/CheckoutPage/SkrillSuccess';
import CardSuccess from './components/CheckoutPage/CardSuccess';
import ProtectedRoute from './components/ProtectedRoute'
import Logout from "./pages/Logout"
import AccountInformation from './pages/AccountInformation';
import SupportTickets from './pages/SupportTickets';
import SupportMessages from './pages/SupportMessages';
import IsAdmin from "./components/Security/IsAdmin"
//import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebase";
import ListUsers from './pages/admin/ListUsers';
import UserDetail from './pages/admin/UserDetail';
function App() {
 /* const [isTawkLoaded, setIsTawkLoaded] = useState(false)
  const tawkMessengerRef = useRef();
  useEffect(() => {
    if (tawkMessengerRef.current == null || !user || !isTawkLoaded || !localStorage.getItem('email')) return
    tawkMessengerRef.current.setAttributes({
        name: localStorage.getItem('username') || '',
        email: localStorage.getItem('email')  || '',
    }, function (error) {
        console.log(error)
    })
}, [isTawkLoaded, user])
*/
const [user, setUser] = useState(null);

useEffect(() => {
  onAuthStateChanged(auth, (user) => {
  if (user) {
      setUser(true);
  } else {
      setUser(false);
  }
  });
}, []);

  return (
    <>
      {/*<TawkMessengerReact
          onLoad={() => { setIsTawkLoaded(true) }}
          propertyId="66895addeaf3bd8d4d18d995"
          widgetId="1i248lseh"
          ref={tawkMessengerRef}/>*/}
      <BrowserRouter>
        <Routes>
          {/* <Route path="/preSalePage" element={<PreSalePage />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/Login" element={<LoginPage />} />
          <Route path="/SignUp" element={<SignUp/>} />
          <Route path="/" element={<Home />} />
          <Route path="/support" element={<Support/>}/>
          <Route path="/logout" element={<Logout />} />
           
          
           {/* Protected routes */}
            <Route
              path="/*"
              element={<ProtectedRoute key={null} type={""} props={undefined} />}
            >
            <Route path="start-test" element={<Dashboard />} />
            <Route path="affiliates" element={<Affiliates />} />
            <Route path="platforms" element={<Platforms />} />
            <Route path="top-worldwide" element={<TopWorldwide />} />
            <Route path="dashboard" element={<FundedAccounts />} />
            {/*<Route path="withdraw" element={<Withdraw />} />*/}
           <Route path="account/:accountId" element={<AccountInformation />} />
             {/*Users Pages (admin only)*/}
             <Route path="admin/*" element={<IsAdmin/>}>
              <Route path="users" element={<ListUsers />} />
              <Route path="user/:userId" element={<UserDetail />} />
              <Route path="support/tickets" element={<SupportTickets />} />
              <Route path="support/messages" element={<SupportMessages />} />
              <Route path="user/:userId/account/:accountId" element={<AccountInformation />} />
            </Route>
          </Route>
          <Route path="/register" element={<EmailRegistration />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndCondition />} />
          <Route path="/general-disclaimer" element={<GeneralDisclaimer />} />
          <Route path="/complete-skrill" element={<SkrillSuccess />} />
          <Route path="/complete-card" element={<CardSuccess />} />
          <Route path="/refund-cancellation" element={<RefundAndCancellation />} />
         {/*} <Route path="/payment/check" element={<CheckPayCard />} />/*}
         {/* <Route path="/checkout/" element={<CheckoutPage />} />*/}
        </Routes>
      </BrowserRouter>
    </>

  );
}

export default App;